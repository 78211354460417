/* tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
}

/* Full screen background image */
.bg-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./Images/glassyBgLoggedIn.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1; /* Ensure it is behind other content */
}

/* Ensure the container grows with content */
.full-height-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bg-loggedin {
  background-image: url('./Images/glassyBgLoggedIn.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; 
}





.overlay {
  /* This class should be applied to the main container when the dialog is active */
  position: relative;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Gray overlay with opacity */
  z-index: 10; /* Ensure it's above the main content but below the dialog */
}

.background-with-overlay {
  position: relative;
  /* background-image: url('./Images/bgImageHighContrast.jpg'); */
  background-image: url('./Images/bgLoggedOut.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 86vh;
  width: 100vw;
}

.background-with-overlay-2 {
  position: relative;
  overflow: hidden; /* This will clip the background */
  height: 90vh; /* Set the visible part to 100vh */
  width: 100vw;
}

.background-with-overlay-2::before {
  content: "";
  position: absolute;
  top: -80vh; /* Move the background up to start at -80vh */
  left: 0;
  height: 1000vh; /* Original stretched height */
  width: 100vw;
  background-image: url('./Images/automatedNotetaking.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}




@keyframes progressAnimation {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(200%); }
}

.animate-progress {
  width: 50%;
  animation: progressAnimation 0.75s infinite linear;
}

/* Define a custom class for the background image */

.bg-loggedout {
  background-image: url('./Images/automatedNotetaking.jpg')
}

/* Custom shadow style */
.btn-shadow {
  --tw-shadow-color: #FFFFFF; /* Shadow color */
  box-shadow: 0 4px 4px rgba(255, 255, 255, 0.25); /* x-offset, y-offset, blur-radius, color */
}

.btn-shadow-2 {
  --tw-shadow-color: #FFFFFF; /* Shadow color */
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.25); /* x-offset, y-offset, blur-radius, color */
}

.logged-in-gradient {
  background-image: linear-gradient(207deg, #2A5A96 0%, #061A33 28%, #000000 100%);
}




.shadow-3 {
  --tw-shadow-color: #FFFFFF; /* Shadow color */
  box-shadow: 0 4px 4px rgba(255, 255, 255, 0.25); /* x-offset, y-offset, blur-radius, color */
}

.mm-text-shadow{
  --tw-shadow-color: #000000; /* Shadow color */
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5); /* x-offset, y-offset, blur-radius, color */
}

.typing-indicator {
  animation: blinkAnimation 1.4s infinite both;
}

@keyframes blinkAnimation {
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
}
