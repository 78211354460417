.hidden {
  opacity: 0;
}

.animate-fadeIn {
  animation: fadeIn 2s ease-in-out forwards;
}

.animate-fadeInFromLeft {
  animation: fadeInFromLeft 1.5s ease-in-out forwards;
}

.animate-fadeInFromRight {
  animation: fadeInFromRight 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Hide the seek bar */
.video-player::-webkit-media-controls-timeline,
.video-player::-webkit-media-controls-seek-back-button,
.video-player::-webkit-media-controls-seek-forward-button {
  display: none;
}
